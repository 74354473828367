import { version, versionBuild } from '../../package.json';

export const environment = {
    production: true,
    public: false,
    apiUrl: 'https://as.kukacka.netbox.cz/api-v2/',
    whitelistedDomains: [ 'as.kukacka.netbox.cz' ],
    blacklistedRoutes: [ 'https://as.kukacka.netbox.cz/api-v2/register' ],
    sentry: {
        dsn: 'https://12d7fdcb851c47528d13b4cc1d6e3678@sentry.kuki.cz/34',
        dsnStb: 'http://12d7fdcb851c47528d13b4cc1d6e3678@sentry.kuki.cz/34',
    },
    gaId: 'UA-2174093-16',
    version: version,
    versionBuild: versionBuild
};
